import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getUsersByEmail = async (token, email) => {
    return await axios.get(`${baseUrl}/users/search/findUserByEmailEquals?email=${email.toLowerCase()}`, {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + token,
        },
    });
};

export { getUsersByEmail };
