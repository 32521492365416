import { init as initApm } from '@elastic/apm-rum'

const apmEnabledStr = process.env.REACT_APP_RUM_APM_ENABLED || "true"
const isApmEnabled = JSON.parse(apmEnabledStr.toLowerCase())

if (isApmEnabled) {
    console.log("apm enabled")
    initApm({
        serviceName: 'uup-ui',
        serverUrl: process.env.REACT_APP_RUM_APM_SERVER_URL,
        environment: process.env.REACT_APP_RUM_APM_ENV,
        breakdownMetrics: true,
    })
} else {
    console.log("apm disabled")
}
