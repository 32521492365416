import React from "react";
import "./Searchbar.scss";

const Searchbar = ({ placeholder, searchByEmail, searchTerm, setSearchTerm, text }) => {
    let className = searchTerm === "" ? "btn btn-primary btn-disabled" : "btn btn-primary";

    // Enter only  
    const handleKeypress = e =>  searchTerm !== "" && (e.which === 13) && searchByEmail(e)
        
    return (
        <div className="search my-30">
            <input
                className="search-bar p-10"
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeypress}
                placeholder={placeholder}
                type="search"
                value={searchTerm}
            />
            <button id="profileSearch" className={className} onClick={searchByEmail} type="submit">
                {text}
            </button>
        </div>
    );
};

export default Searchbar;
