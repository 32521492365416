import React from "react";
import ReactDOM from "react-dom";
import "index.scss";
import App from "App";
import { GoogleAuthProvider } from "auth/googleAuth";
import 'config/apmConfig'

ReactDOM.render(
    <GoogleAuthProvider>
        <App />
    </GoogleAuthProvider>,
    document.getElementById("root")
);
