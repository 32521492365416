import React from "react";
import { useGoogleAuth } from "auth/googleAuth";
import './Login.scss';

const Login = () => {
    const { signIn } = useGoogleAuth();

    return (
        <>
            <div className="headline text-primary top-left m-10">ARIA Profile Login </div>
            <button onClick={signIn} className="btn btn-lg m-auto" id="button-google-sign-in">
                <img src="icons/google.svg" alt="google login"/>
                <div className="login-text">Sign in with Google</div>
            </button>
        </>
    );
}

export default Login;