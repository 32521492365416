import { BrowserRouter } from "react-router-dom";
import ARIAEmailSearch from "./views/search/ARIAEmailSearch";
import PublicRouter from "./router/PublicRouter";
import PrivateRouter from "./router/PrivateRouter";
import Login from "./components/Login/Login";
import Layout from './components/Layout/Layout';

function App() {
    return (
        <Layout>
            <BrowserRouter>
                <PublicRouter path="/login" component={Login} />
                <PrivateRouter path="/" component={ARIAEmailSearch} />
            </BrowserRouter>
        </Layout>
    );
}

export default App;
