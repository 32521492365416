// Add footer, header, and/or sections here
import "./Layout.scss";

const Layout = (props) => {
  return (
    <>
      <main>{props.children}</main>
    </>
  );
};

export default Layout;