import React from "react";
import { Redirect } from "react-router-dom";
import { useGoogleAuth } from "auth/googleAuth";
import { ApmRoute } from '@elastic/apm-rum-react'

const PrivateRouter = ({ component: Component, ...rest }) => {
    const { isSignedIn } = useGoogleAuth();
    return (
        <>
            { isSignedIn
                ? <ApmRoute {...rest} component={Component}/>
                : <Redirect exact from="/dashboard" to="/login"/> }
        </>
    );
};

export default PrivateRouter;
