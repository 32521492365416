import React from "react";
import "./EmailSearchUserData.scss";

const EmailSearchUserData = ({foundUser, isSmartMatch}) => {

    const tableData = [
        {
            label: "AUID",
            value: foundUser.id,
        },
        {
            label: "Born On Date",
            value: foundUser.birthDate,
        },
        {
            label: "Email Address",
            value: foundUser.email,
        },
        {
            label: "First Name",
            value: foundUser.firstName,
        },
        {
            label: "Last Name",
            value: foundUser.lastName,
        },
        {
            label: "Phone Number",
            value: foundUser.phoneNumber,
        },
        {
            label: "Address 1",
            value: foundUser.address && foundUser.address.address1
        },
        {
            label: "Address 2",
            value: foundUser.address && foundUser.address.address2
        },
        {
            label: "City",
            value: foundUser.address && foundUser.address.city
        },
        {
            label: "State/Region",
            value: foundUser.address && foundUser.address.state,
        },
        {
            label: "Zip Code",
            value: foundUser.address && foundUser.address.zipCode,
        },
        {
            label: "Country",
            value: foundUser.address && foundUser.address.country,
        },
    ];

    return tableData.map((data) => {
        return (
            <div className="user-info my-10" key={data.label}>
                {data.label}
                <input disabled={isSmartMatch} type="text" value={data.value || ""} />
            </div>
        );
    });
};

export default EmailSearchUserData;
