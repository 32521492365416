import './Spinner.scss';

const Spinner = () => {
  return (
      <div className="loading">
        <div className="spinner"></div>
        <div className="headline text-primary">Loading...</div>
      </div>
  );
}

export default Spinner