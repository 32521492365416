import { getUsersByEmail } from "./api";

const searchByEmail = async (token, email) => {
    const resp = await getUsersByEmail(token, email);
    const users = resp.data._embedded.users;
    if (users && users.length > 0) {
        return users.map((u) => {
            delete u._links;
            return u;
        });
    } else {
        throw new Error(`No user found for ${email}`);
    }
};

export { searchByEmail };
