import React, { useState } from "react";
import { useGoogleAuth } from "auth/googleAuth";

import EmailSearchUserData from "./EmailSearchUserData";
import Searchbar from "components/common/Searchbar/Searchbar";
import ToggleSwitch from "components/common/ToggleSwitch/ToggleSwitch";
import Spinner from "components/common/Spinner/Spinner";
import * as u from "users";
import "users";

import "./ARIAEmailSearch.scss";

const ARIAEmailSearch = () => {
    const smartMatcher = false;
    const [searchTerm, setSearchTerm] = useState("");
    const [error, setError] = useState("");
    const [foundUser, setFoundUser] = useState({});
    const [isSmartMatch, setSmartMatch] = useState(true);
    const { googleUser } = useGoogleAuth();
    // Move this to context/store. This should be a global component
    const [isLoading, setLoading] = useState(false);

    const searchByEmail = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const users = await u.searchByEmail(googleUser.tokenObj.id_token, searchTerm);
            setError("");
            setFoundUser(users[0]);
        } catch (error) {
            setFoundUser({});
            setError(error.message);
        }
        setLoading(false);
    };

    const displayData = () => {
        return (
            <>
                <div className="regular text-primary my-5">{isSmartMatch ? "ARIA User Profile" : "Add ARIA User"}</div>
                <div className="container">
                    <div className="body bg-light">
                        {error && error !== "" && searchTerm !== "" && <div className="error">{error}</div>}
                        <div className="data">
                            {<EmailSearchUserData foundUser={foundUser} isSmartMatch={isSmartMatch} />}
                        </div>
                    </div>
                    {smartMatcher && (
                        <div className="footer bg-light">
                            <ToggleSwitch
                                isChecked={isSmartMatch}
                                label="Smart Matching"
                                setSmartMatch={setSmartMatch}
                            />
                            <div className="edit">
                                <button id="addFields" className="btn btn-primary btn-disabled mx-10">
                                    {isSmartMatch ? "Add Fields" : "Save"}
                                </button>
                                <button id="editUser" className="btn btn-extra-light btn-disabled">
                                    {isSmartMatch ? "Edit User" : "Cancel"}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };

    return (
        <>
            <div className="headline text-primary top-left m-10">ARIA Profile Email Search</div>
            {isSmartMatch && (
                <Searchbar
                    placeholder="Email Address"
                    searchByEmail={searchByEmail}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    text="SEARCH"
                />
            )}
            {isLoading ? <Spinner /> : (foundUser.email && displayData()) || (error && displayData())}
        </>
    );
};
export default ARIAEmailSearch;
