import React from "react";
import { Redirect } from "react-router-dom";
import { useGoogleAuth } from "auth/googleAuth";
import { ApmRoute } from '@elastic/apm-rum-react'

const PublicRouter = ({ component: Component, ...rest }) => {
    const { isSignedIn } = useGoogleAuth();
    return (
        <>
            { !isSignedIn
                ? <ApmRoute {...rest} component={Component}/>
                : <Redirect exact to="/dashboard" /> }
        </>
    );
};

export default PublicRouter;
